<template>
<v-card flat :dark="$store.getters.getColorPalette().isDark" :color="$store.getters.getColorPalette().backgroundColorCode">
<v-container fluid>
<v-card  :color="$store.getters.getColorPalette().backgroundColorCode" :dark="$store.getters.getColorPalette().isDark" flat>
        <v-card-title>{{ $store.getters.getTextMap().choose_param_to_monitor }}
      <InfoComponent  hint="Shows real-time monitoring"/>
        </v-card-title>
    <v-card-subtitle>
            <v-form
    ref="monitorForm"
    v-model="valid"
    
  >
            <v-row>
                      
        
            <v-col>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                v-if="!loading"
              v-model="form.workspace"
          :items="workspaceOptions"
          :label="$store.getters.getTextMap().workspace"
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
            <v-col>
              <v-skeleton-loader
        v-if="loading"
      class="mx-auto"
      type="text"
    ></v-skeleton-loader>
                <v-select
                v-if="!loading"
              v-model="form.device"
          :items="deviceOptions"
          :label="$store.getters.getTextMap().device"
          outlined
          item-text="label"
          item-value="value"
          required
            :rules="objectRules"
             :menu-props="{dark: $store.getters.getColorPalette().isDark}"
        ></v-select>
            </v-col>
        <v-col>
    <v-skeleton-loader v-if="loading" class="mx-auto" type="text"></v-skeleton-loader>
    <v-autocomplete
      v-if="!loading"
      v-model="form.parameter"
      :items="paramOptions"
      :label="$store.getters.getTextMap().device_parameter"
      outlined
      item-text="label"
      item-value="value"
      required
      :rules="objectRules"
      :menu-props="{ dark: $store.getters.getColorPalette().isDark }"
    ></v-autocomplete>
  </v-col>
            <v-col align-self="center">
                <v-btn
                v-if="!loading"
           :color="$store.getters.getColorPalette().submitbtnColor"
         
            small
            @click="addParam"
          >
          {{ $store.getters.getTextMap().add_parameters }}
          </v-btn>
            </v-col>
        </v-row>
            </v-form>
        </v-card-subtitle>
    
    </v-card>
    <v-divider></v-divider>
    
    <v-row><v-chip
      class="ma-2"
      close
      :color="$store.getters.getColorPalette().chipColor"
      :text-color="$store.getters.getColorPalette().accentCode" v-for="param in parameters"
      @click:close="removeParam(param)"
        :key="param">
        {{getParamName(param)}}
        </v-chip></v-row>
    <v-row><v-col align-self="center" align="center" cols="12"><div ref="graph" >

      </div></v-col></v-row>

      
</v-container>
</v-card>
</template>

<script>
 import axios from 'axios'
import moment from 'moment'
import Plotly from "plotly.js"
import {io} from 'socket.io-client'
import InfoComponent from '@/components/InfoComponent'



export default {
    name:'RealTimeMonitoringPanel',
    components:{
      InfoComponent,
   
     
    },
   
    mounted(){
        this.createGraph()

        this.stream.on("connect_error", (err) => {
  console.log(err.message); // prints the message associated with the error
});
      this.stream.on('error',data=>{
      console.error(data)
      
    })

    this.stream.on('param',data=>{
      console.log(data)
      //this.val=data.data
      //console.log(data)
      if(data.constructor==String){
        data=JSON.parse(data)
      }
      //  console.log('Timestamp:', moment.unix(data.timestamp).format('YYYY-MM-DD HH:mm:ss'));
              if (!data.param_id) {
            console.error('Invalid data structure. Missing param_id:', data);
            return;
        }
if (!this.parameterData[data['param_id']]) {
  this.parameterData[data['param_id']] = {
    'x': [],
    'y': []
  };
}

this.parameterData[data['param_id']]['x'].push(moment.unix(data.timestamp).format('YYYY-MM-DD HH:mm:ss'));
this.parameterData[data['param_id']]['y'].push(Number(data.data));

      // console.log(this.parameterData)

      // Plotly.newPlot(this.$refs.graph,Object.values(this.parameterData),this.layout,{displaylogo: false})
      this.createGraph()
      //this.streamData.push(data)
      //console.log(this.parameterData[data.parameter]['x'].length)
      if(this.parameterData[data['param_id']]['x'].length>700){
        this.parameterData[data['param_id']]['x'].shift()
        this.parameterData[data['param_id']]['y'].shift()
      }
    })
    
    },
    created(){
    //this.socket=io('https://localhost:80');
    this.stream=io(this.$store.state.streamApi,{withCredentials: true, auth:{token:this.$store.state.jwt}})
  },
  beforeDestroy() {
    //this.socket.close()
    //console.log('before destroy')
    this.stream.close()
  },
    data(){
        return {
            
            stream:null,
            //parameters:new Set(),
            parameters:[],
            parameterData:{},
            showDismissibleAlert:false,
            loading:false,
            valid:false,
            info:'',
            form:{
                workspace:null,
                device:null,
                parameter:null
            },
            layout:{
              //title:'Compare',
              showlegend:true,
              colorway:this.$store.getters.getColorPalette().colorTypesCodeArray,
              font:{
                          color:this.$store.getters.getColorPalette().accentCode
                        },
              

                plot_bgcolor:this.$store.getters.getColorPalette().backgroundColorCode+'02',

                paper_bgcolor:this.$store.getters.getColorPalette().background2ColorCode,
                    
                    autosize: true,
                    xaxis: {
                        tickangle: -12,
                        zeroline: false,
                        showgrid: true,
                        showticklabels: true,
                       color:this.$store.getters.getColorPalette().accentCode
                      },       
                      yaxis: {
                        showticklabels: true,
                       color:this.$store.getters.getColorPalette().accentCode
                      },
                      uniformtext: {
                        "mode": "hide",
                        "minsize": 1
                      }
            },
             objectRules:[
                v => !!v || 'Required',
              ],
        }
    },
    computed:{
        workspaceOptions(){
            let op=[]
          
              let g=this.$store.state.workspaces
              for(let i of g){
                  //op.push({value:i, text: i.name })
                  op.push( {value:i, label: i.name })
                }
          return op
        },
        deviceOptions(){
             
            let op=[]
          
          if(this.form.workspace && this.form.workspace.workspace_id ){
              let g=this.$store.getters.getDevicesByWorkspaceId(this.form.workspace.workspace_id)
              for(let i of g){
                  
                  if(i && i.name ){
                    
                    op.push({value:i, label: i.name })
                  }else{
                    op.push({value:i, label: i.snode_id })
                  }
                  
              
          }
          }
          return op
        },
        paramOptions(){
             
            let op=[]
          
          if(this.form.device && this.form.device.device_id ){
              let g=this.$store.getters.getParametersByDeviceID(this.form.device.device_id)
              for(let i of g){
                  //console.log(this.parameters.indexOf(i.parameter)==-1)
                  if(i && (this.parameters.indexOf(i.parameter)!=-1)){
                      continue
                  }
                  if(i && i.name ){
                    
                    op.push({value:i.parameter, label: i.name })
                  }else{
                    op.push({value:i.parameter, label: i.parameter })
                  }
                  
              
          }
          }
          return op
        },
        
    },
    methods:{
        getParamName(param){
          let p=this.$store.getters.getParameterById(param)
          if(p){
          return this.$store.getters.getDeviceById(p['device_id'])['name'] +"-"+p['name']
          }else{
            return "Issue with getting Param Name"
          }
        },
        createGraph(){
            // let trace1 = {
            // x: [],
            // y: [],
            // fill: 'tozeroy',
            // type: 'scatter'
            // };

            // let trace2 = {
            // x: [],
            // y: [],
            // type: 'scatter'
            // };

            // let data = [trace1, trace2];

            Plotly.newPlot(this.$refs.graph,Object.values(this.parameterData),this.layout,{displaylogo: false})
                    },
                    
addParam() {
    this.$refs.monitorForm.validate();
    if (this.valid) {
        let paramId = this.form.parameter; // Get the selected parameter

        let payload = {
            params: [paramId],
            from_timestamp: moment().subtract(6, 'hours').unix()
        };

        axios.post(this.$store.state.api + 'getRawDataByParamsBetweenTimestamp', payload, { headers: { Authorization: 'Bearer ' + this.$store.state.jwt } })
            .then(response => {
                let x = [];
                let y = [];

                for (let i of response.data.data) {
                    x.push(moment.unix(i.timestamp).format('YYYY-MM-DD HH:mm:ss'));
                    y.push(Number(i.data));
                }

                // Initialize or update parameterData for the specified param_id
                this.parameterData[paramId] = {
                    x: x,
                    y: y,
                    type: 'scatter',
                    name: this.getParamName(paramId),
                };
                this.parameters.push(paramId);
                this.stream.emit('getParam', paramId);
                this.createGraph();

        // Set up real-time streaming for the specified param_id
                this.stream.on('param_' + paramId, data => {
                    x.push(moment.unix(data.timestamp).format('YYYY-MM-DD HH:mm:ss'));
                    y.push(Number(data.data));
                    // Keep only the last 700 data points
                    if (x.length > 700) {
                        x.shift();
                        y.shift();
                    }
                    this.parameterData[paramId] = {
                        x: x,
                        y: y,
                        type: 'scatter',
                        name: this.getParamName(paramId),
                    };

                  
                    this.createGraph();
                });
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }
},

        removeParam(param){
            console.log("remove param")
            console.log(param)
            this.stream.emit('removeParam',param)
            this.parameters=this.parameters.filter(x=>x!=param)
            //this.parameter.delete(param)
            delete this.parameterData[param]
            // Plotly.newPlot(this.$refs.graph,Object.values(this.parameterData))
            this.createGraph()
        }

    }
}
</script>

 <style scoped>

</style>