<template>
    <v-container fluid>
       
        <v-row>
            <v-col cols="12">
                <MonitorPage/>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import MonitorPage from '@/pages/MonitorPage'
export default {
    name:'Monitor',
    components:{
        MonitorPage
    },
    mounted(){
        document.title='Monitor'
        if (!this.$store.state.loggedIn) {
      this.$router.push("/");
    }
    }

}
</script>
<style scoped>

</style>