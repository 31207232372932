<template>
    <v-container fluid>
        
        <v-row>
            <v-col><RealTimeMonitoringPanel/></v-col>
        </v-row>
        

    </v-container>
</template>
<script>
//import ParameterComparisonPanel from '@/components/panels/ParameterComparisonPanel'
import RealTimeMonitoringPanel from '@/components/panels/RealTimeMonitoringPanel'

//import Plotly from "plotly.js"
export default {
    name:'MonitorPage',
    components:{
        //ParameterComparisonPanel,
        RealTimeMonitoringPanel,
       
    },
    mounted(){
        //this.createGraph()
    },
    data(){
        return {}
    },
    
}
</script>